import React from "react"
import { graphql } from "gatsby"
import EventGroups, { ALL } from "../../components/EventGroups/eventGroups"
import Footer from "../../components/Footer/footer"
import Info from "../../components/Info/info"
import "./group-template.scss"
import BaseLayout from "../../components/Layout/layout"

const GroupTemplate = ({ data, pageContext }) => {
  //console.log("page context", pageContext)
  const group = data.group
  const eventEdges = data.events ? data.events.edges : []
  const groupLogo = group.logoUri ? group.logoUri : group.logoUriNeg
  return (
    <BaseLayout>
      <div className="group-header-container">
        <div className="group-header">
          <img
            src={`/logos/competition/${groupLogo}`}
            onError={e =>
              e.target.setAttribute("src", "/logos/competition/fallback.png")
            }
            className="group-logo"
            alt={group.name}
          />
          <h1 className="group-name">{group.name}</h1>
        </div>
      </div>
      <Info
        header={`Hitta rätt ${group.name}-match`}
        description={`Här kan du se de kommande matcherna i ${group.name} som sänds i TV eller
        via en stramingtjänst på nätet. Klicka på loggorna för mer information om respektive sändning
        och hur du får tag på matchen. Du kan även klicka på matcherna för att få information om
        matchodds, eventuella speltips och förhandsanalyser.`}
      />
      <div className="group-content-container">
        <EventGroups
          key={`group-events-${group.name}`}
          eventEdges={eventEdges}
          numberOfDays={ALL}
        />
      </div>
      <Footer links={pageContext.allLinks} />
    </BaseLayout>
  )
}

export default GroupTemplate

export const pageQuery = graphql`
  query groupQuery($id: String!) {
    group: contentfulGroup(contentful_id: { eq: $id }) {
      name
      logoUri
      logoUriNeg
    }
    events: allContentfulEvent(
      filter: { groupId: { eq: $id }, node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          group {
            name
            uri
          }
          league {
            name
          }
          sport {
            code
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
          channelLinks {
            channel
            externalUrl
          }
          channels {
            id
            name
            externalUrl
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
